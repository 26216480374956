import React from 'react';
import { ImageBox } from '../basic/ImageBox';
import TabPanel from '../basic/TabPanel';
import ConfigTabPanel from '../configs/ConfigTabPanel';
import './adminPanel.css';
import CacheReloadPanel from './CacheReloadPanel';
import UpdatePanel from './UpdatePanel';

export default function AdminPanel() {
    return <TabPanel contextStyle='admin-app'>
        {{
            panelLabel: <ImageBox iconName='Settings' label='System configuration' className='tabButton' />,
            panelContent: <ConfigTabPanel />
        }}
        {{
            panelLabel: <ImageBox iconName='Settings' label='Cache reload' className='tabButton' />,
            panelContent: <CacheReloadPanel />
        }}
        {{
            panelLabel: <ImageBox iconName='databaseconfig_w' label='DB Update' className='tabButton' />,
            panelContent: <UpdatePanel />

        }}
    </TabPanel>
}
