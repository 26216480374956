import { OpenItemEvent } from '../../model/events/TactinEvents';
import { createNewItem } from '../../model/item/Item';
import { ClientItem, Item } from '../../model/item/ItemTypes';
import { ItemUtils } from '../../model/item/Utils';
import { ActionContextProvider, ItemActionContext } from '../../model/systemactions/ActionContext';
import { api } from '../../utils/api/ApiProvider';
import { Failed } from '../../utils/api/TactinApi';

async function createNewItemAsync(categoryId: number, contextMap: { [key: string]: string }, ownerId?: number, contextProvider?: ActionContextProvider) {
    if (!categoryId)
        throw new Error('Cannot create an item without a category.');
    const category = await api().Item.getCategory(categoryId);
    const asyncResult: ClientItem = createNewItem(category, ownerId);
    updateContext(asyncResult.item, contextMap, contextProvider);
    return asyncResult;
}

function updateContext(item: Item, contextMap: { [key: string]: string }, contextProvider?: ActionContextProvider) {
    if (!contextMap)
        return;
    const context = contextProvider && contextProvider('item');
    if (context && context instanceof ItemActionContext) {
        const parentItem = context.item;
        for (const prp in contextMap) {
            if (Object.prototype.hasOwnProperty.call(contextMap, prp)) {
                ItemUtils.setFieldValue(item, prp, ItemUtils.getFieldValue(parentItem.item, contextMap[prp]));
            }
        }
    }
}

async function openItem(event: OpenItemEvent): Promise<ClientItem> {

    if (event.id > 0)
        return await api().Item.getItem(event.id);
    else if (event.item)
        return { item: event.item, permission: 'delete' }
    else if (event.clientItem)
        return event.clientItem
    else if (event.categoryId > 0)
        return await createNewItemAsync(event.categoryId,
            event.contextMap,
            undefined,
            event.contextProvider);
    else
        throw new Failed('Incomplete data in OpenItemEvent.');
}

export const ItemFactory = {
    openItem
}
