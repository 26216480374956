import React, { useEffect, useState } from 'react';
import { ShowMessageEvent } from '../../model/events/TactinEvents';
import { DatabaseInfo, PreLogAdminApi } from '../../utils/api/AdminApi';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { TUpdateInfo, TUpgradeResult } from '../../utils/api/ApiTypes';
import { tactin } from '../../utils/TactinGlobals';
import { ImageBox } from '../basic/ImageBox';
import { useQuestionBox } from '../basic/QuestionBox';

export default function UpdatePanel() {
    const [versionsInfo, setVersionsInfo] = useState<DatabaseInfo | null>(null);
    const [availableUpdateVersions, setAvailableUpdateVersions] = useState<TUpdateInfo[] | null>(null);
    const [selectedVersion, setSelectedVersion] = useState<TUpdateInfo | null>(null);
    const [versionUpdatedResult, setVersionUpdatedResult] = useState<TUpgradeResult[] | null>(null)

    return <div className='panelContent'>
        <UpdateControl versionsInfo={versionsInfo} setVersionsInfo={setVersionsInfo}
            availableUpdateVersions={availableUpdateVersions} setAvailableUpdateVersions={setAvailableUpdateVersions}
            selectedVersion={selectedVersion} setSelectedVersion={setSelectedVersion}
            versionUpdatedResult={versionUpdatedResult} setVersionUpdatedResult={setVersionUpdatedResult} />
        <UpdateInfo selectedVersion={selectedVersion} versionUpdatedResult={versionUpdatedResult} />
    </div>
}

type ControlProps = {
    versionsInfo: DatabaseInfo | null;
    setVersionsInfo: (value: DatabaseInfo) => void;
    availableUpdateVersions: TUpdateInfo[] | null;
    setAvailableUpdateVersions: (value: TUpdateInfo[]) => void;
    selectedVersion: TUpdateInfo | null;
    setSelectedVersion: (value: TUpdateInfo | null) => void;
    versionUpdatedResult: TUpgradeResult[] | null,
    setVersionUpdatedResult: (value: TUpgradeResult[]) => void;
}

function UpdateControl(props: ControlProps) {
    const { askQuestion, QuestionControl } = useQuestionBox('');

    useEffect(() => {
        if (!props.versionsInfo)
            getVersionsInfo();
    }, [])

    function getVersionsInfo() {
        PreLogAdminApi().getDatabaseInfo().then(r => props.setVersionsInfo(r)).catch(HandleErrors());
        api().Admin.getAvailableUpdatesInfo().then(r => props.setAvailableUpdateVersions(r)).catch(HandleErrors());
    }

    function updateToVersion() {
        if (props.selectedVersion) {
            askQuestion({
                content: `Are you sure you want to update the database to the selected version ${props.selectedVersion?.number} ? One or more selected updates needs a manual change in the database for complete configuration.`,
                onYes: () => {
                    if (props.selectedVersion)
                        api().Admin.updateDatabaseToVersion(props.selectedVersion.number).then(r => updateVersionsInfo(r)).catch(HandleErrors(true))
                }
            })
        } else
            tactin()?.eventBus.notify(new ShowMessageEvent('Please Select a version first!'))
    }

    function updateToLastVersion() {
        askQuestion({
            content: `Are you sure you want to update the database to the newest available version ${props.versionsInfo?.required} ?`,
            onYes: () => {
                if (props.versionsInfo)
                    api().Admin.updateDatabaseToVersion(props.versionsInfo.required).then(r => updateVersionsInfo(r)).catch(HandleErrors(true))
            }
        })
    }

    function updateVersionsInfo(r: TUpgradeResult[]) {
        let info;
        if (!props.versionUpdatedResult)
            info = [];
        else
            info = JSON.parse(JSON.stringify(props.versionUpdatedResult));
        r.forEach(i => info.push(i));
        props.setVersionUpdatedResult(info);
        props.setSelectedVersion(null);
        getVersionsInfo();
    }

    const infoBlock = (value: string) => <div className='infoBlock'>
        <div>{`${value[0].toUpperCase() + value.slice(1)} DB version:`}</div>
        <div>{props.versionsInfo ? String(props.versionsInfo[value as keyof DatabaseInfo]).padStart(5, '0') : '-----'}</div>
    </div>

    return (
        <div className='functionsButtonHolder'>
            <ImageBox iconName='databaseconfig' className='DBUpdateHeader' label='On this panel you can update the database.' />
            {infoBlock('current')}
            {infoBlock('required')}
            <div className='updatePickList'>
                {props.availableUpdateVersions && props.availableUpdateVersions.map(i => <div key={i.number}
                    className={`updateItem ${i.number === props.selectedVersion?.number ? 'selectedItem' : ''}`}
                    onClick={() => props.setSelectedVersion(i)}>
                    <div className={`${i.manualChanges.length ? 'manual' : ''}`} />
                    <span>{String(i.number).padStart(5, '0')}</span>
                </div>)}
            </div>
            <div className='buttons'>
                <ImageBox className='functionsButton' iconName='box41_32x32' label='Update to selected version' onClick={updateToVersion} />
                <ImageBox className='functionsButton' iconName='box41_32x32' label='Execute ALL Updates' onClick={updateToLastVersion} />
            </div>
            {QuestionControl}
        </div>
    )
}

type InfoProps = {
    selectedVersion: TUpdateInfo | null;
    versionUpdatedResult: TUpgradeResult[] | null,
}

function UpdateInfo(props: InfoProps) {
    return <div className='infoPanel'>
        <div className='updateInfoPanel'>{`[Selected update info] `}
            {props.selectedVersion && <>
                <strong>{`UPDATE ${String(props.selectedVersion.number).padStart(5, '0')}`}</strong>{` - ${props.selectedVersion.description}`}
                {props.selectedVersion.manualChanges.length ? <><br />{'[Manual configuration needed]'}
                    <ul>{props.selectedVersion.manualChanges.map((text, ind) => <li key={ind}>{text}</li>)}</ul>
                </> : null}
            </>}
        </div>
        <div className='outputPanelLabel'>[Output info] </div>
        {props.versionUpdatedResult &&
            <div className='updateOutputPanel'>{props.versionUpdatedResult.map((i, ind) =>
                <div key={i.upgradeName + ind}>
                    <strong>{`UPDATE ${i.upgradeName} `}</strong>
                    {`${i.result ? 'Success' : 'Failed'}`}<br />
                    <div>{(i as any).resultDesctiption.split('</br>')
                        .map((part: string, index: number) => <div key={index}>{part}</div>)}
                    </div>
                </div>)}
            </div>}
    </div>
}
