import React, { useLayoutEffect, useRef, useState } from 'react';
import { TTactinEventBus } from '../../model/events/EventBus';
import { LoginEvent } from '../../model/events/TactinEvents';
import { ConnectionError, ExecutionError, Failed } from '../../utils/api/TactinApi';
import { PreLoginUserApi } from '../../utils/api/UserApi';
import { Loader } from '../basic/Loader';
import './LoginWindow.css';

type LoginWindowProps = {
    asAdmin: boolean;
    eventBus: TTactinEventBus;
}
export default function LoginWindow(props: LoginWindowProps) {
    const [errorMsg, setError] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const buttonRef = useRef<HTMLButtonElement>(null);

    const doLogin = () => {
        if (user) {
            PreLoginUserApi.login(user, password || '', props.asAdmin)
                .then(v => {
                    props.eventBus.notify(new LoginEvent(v));
                })
                .catch(err => {
                    if (err instanceof Failed)
                        setError(err.message);
                    else if (err instanceof ExecutionError)
                        setError('Execution error!');
                    else if (err instanceof ConnectionError)
                        setError('Connection error!');
                })
        }
    }
    const errorCleanup = () => {
        setError('');
        setPassword('');
    }
    useLayoutEffect(() => {
        if (buttonRef.current)
            buttonRef.current.focus();
    })

    return <>{!errorMsg ?
        <div className='login-window' onKeyDown={e => e.key === 'Enter' && doLogin()} >
            <input className='login-name' type='text' placeholder='Użytkownik' value={user} onChange={e => setUser(e.target.value)} />
            <input className='login-password' type='password' placeholder='Hasło' value={password} onChange={e => setPassword(e.target.value)} />
            <button className='login-button' onClick={doLogin} >ZALOGUJ</button>            
        </div>
        :
        <div className='login-window'>
            <div className='err-message'>
                {errorMsg || '.'}
            </div>
            <button ref={buttonRef} className='login-button' onClick={errorCleanup}>OK</button>
        </div>}
    </>
}
