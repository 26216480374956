import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOM from 'react-dom';
import TactinEventBus from '../../model/events/EventBus';
import { CloseItemEvent, LoginEvent, LogoutEvent, OpenConfigurationEvent, OpenItemEvent, ReloadConfigEvent, ReplaceItemEvent, SessionHeartbeatEvent, ShowMessageEvent, TactinEvent } from '../../model/events/TactinEvents';
import TactinConfig, { TactinConfigData } from '../../model/TactinConfig';
import { PreLogAdminApi } from '../../utils/api/AdminApi';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { LoginResult } from '../../utils/api/Results';
import { initGlobalState, tactin } from '../../utils/TactinGlobals';
import AdminPanel from '../admin/AdminPanel';
import { Loader } from '../basic/Loader';
import WaitNotifier from '../basic/WaitNotifier';
import CardManager, { useCardManager } from '../cards/CardManager';
import LoginWindow from '../security/LoginWindow';
import { ItemFactory } from './ClientItemFactory';
import FileDownloader from './FileDownloader';
import './main.css';
import PopupCustomUI from './PopupCustomUI';
import { SplashScreen } from './SplashScreen';
import TactinMessage from './TactinMessage';
import TokenOutdatedHandler from './TokenOutdatedHandler';
import UIFactory from './UIFactory';

const initState = {
    dbstatus: 'check' as 'check' | 'ok' | 'skip',
    loginStatus: null as (LoginResult | null),
    api: false as boolean,
    clientConfig: null as (TactinConfigData | null),
    error: ''
}

const loginAction = (loginData: LoginResult) => ({ type: 'login' as 'login', data: loginData });
const logoutAction = () => ({ type: 'logout' as 'logout' });
const apiLoadedAction = () => ({ type: 'apiLoaded' as 'apiLoaded' });
const configLoadAction = (config: TactinConfigData) => ({ type: 'configLoad' as 'configLoad', data: config });
const errorAction = (message: string) => ({ type: 'error' as 'error', data: message });
const dbOkAction = () => ({ type: 'db-ok' as 'db-ok' });

type Action =
    | ReturnType<typeof loginAction>
    | ReturnType<typeof logoutAction>
    | ReturnType<typeof apiLoadedAction>
    | ReturnType<typeof configLoadAction>
    | ReturnType<typeof errorAction>
    | ReturnType<typeof dbOkAction>;

function reducer(state: typeof initState, action: Action): typeof initState {
    switch (action.type) {
        case 'login':
            return { ...state, loginStatus: action.data, error: '' };
        case 'logout':
            return { ...state, loginStatus: null, clientConfig: null, error: '' };
        case 'apiLoaded':
            return { ...state, api: true, error: '' };
        case 'configLoad':
            return { ...state, clientConfig: action.data, error: '' };
        case 'error':
            return { ...state, error: action.data };
        case 'db-ok':
            return { ...state, dbstatus: 'ok' }
        default:
            return state;
    }
}

export default function Tactin({ admin }: { admin: boolean }) {
    const eventBus = useRef<TactinEventBus>(new TactinEventBus());

    const [state, dispatch] = useReducer(reducer, { ...initState, dbstatus: admin ? 'skip' : 'check' });

    const handler = (event: TactinEvent) => {
        if (event instanceof LoginEvent)
            handleLoginEvent(event);
        else if (event instanceof LogoutEvent)
            handleLogoutEvent();
        else if (event instanceof ReloadConfigEvent)
            handleReloadConfigEvent(event);
    }

    const handleLoginEvent = (event: LoginEvent) => {
        dispatch(loginAction(event.loginData));
        window.setTimeout(() => eventBus.current.notify(new ReloadConfigEvent('login')), 300);
    };
    const handleLogoutEvent = () => {
        dispatch(logoutAction());
    };
    const handleReloadConfigEvent = (event: ReloadConfigEvent) => {
        switch (event.reason) {
            case "textTemplate":
            case "translation":
            case "cardUsagePlace":
                if (state.clientConfig === null)
                    return;
            case "login":
                // initializing server values, downloading client config
                if (state.loginStatus)
                    TactinConfig.initApi(state.loginStatus.token).then(() => {
                        dispatch(apiLoadedAction());
                        if (!admin)
                            TactinConfig.loadConfig(state.loginStatus)
                                .then((config) => {
                                    dispatch(configLoadAction(config));
                                    if (event.reason === 'translation') {
                                        eventBus.current.notify(new ReloadConfigEvent("mainMenu"));
                                        eventBus.current.notify(new ReloadConfigEvent("mainTabs"));
                                    }
                                })
                                .catch(err => {
                                    dispatch(errorAction('Error while loading configuration.'));
                                    console.log(err);
                                });
                    }).catch(err => {
                        dispatch(errorAction('Error while initializing API.'));
                        console.log(err);
                    });
                else
                    dispatch(errorAction('Internal error. Tried to load config without login information.'));
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (state.dbstatus === 'check')
            PreLogAdminApi().getDatabaseInfo()
                .then(info => {
                    if (info.current !== info.required)
                        dispatch(errorAction('The system database requires update! Please contact Tactin administrator!'));
                    else
                        dispatch(dbOkAction());
                })
                .catch(err => {
                    dispatch(errorAction('Unable to check database version! Please contact Tactin administrator!'));
                    console.log(err);
                });
    }, []);

    useEffect(() => {
        const registration = eventBus.current.register(handler);
        return () => registration();
    });

    if (state.error) {
        if (!state.loginStatus)
            return <ErrorMessage message={state.error} />
        else
            return <ErrorMessage message={state.error} onClose={() => dispatch(logoutAction())} />
    } else {
        if (state.dbstatus === 'check')
            return <SplashScreen message='Checking database version' />
        else if (!state.loginStatus)
            return <LoginWindow asAdmin={admin} eventBus={eventBus.current} />
        else if (!state.api)
            return <SplashScreen message='Initializing API connection...' />;
        else if (admin)
            return <AdminPanel />
        else if (!state.clientConfig)
            return <SplashScreen message='Loading configuration...' />;
        else {
            initGlobalState(eventBus.current, state.clientConfig, state.loginStatus);
            return <App />;
        }
    }
}

function ErrorMessage({ message, onClose }: { message: string, onClose?: () => void }) {
    return <div className="error-message">
        {message}
        {onClose ? <div className="message-close-button" onClick={() => onClose()}>Close</div> : null}
    </div>
}

function App() {
    const sessionTimer = useRef(0);
    const { cards, openCard, closeCard, replaceCard } = useCardManager();

    useEffect(() => {
        refreshSession();
        return () => {
            if (sessionTimer.current)
                clearTimeout(sessionTimer.current);
        }
    }, []);

    useEffect(() => {
        const unregister = tactin().eventBus.register(handler);
        return () => unregister();
    });

    const handler = (event: TactinEvent) => {
        if (event instanceof ReplaceItemEvent)
            handleReplaceItemEvent(event);
        else if (event instanceof OpenItemEvent)
            handleOpenItemEvent(event);
        else if (event instanceof CloseItemEvent)
            handleCloseItemEvent(event);
        else if (event instanceof OpenConfigurationEvent)
            handleOpenConfigurationEvent(event);
        else if (event instanceof SessionHeartbeatEvent)
            refreshSession();
    }

    const handleReplaceItemEvent = (event: ReplaceItemEvent) => {
        ItemFactory.openItem(event).then(ci => {
            if (event.message)
                tactin()?.eventBus.notify(new ShowMessageEvent(event.message));
            replaceCard([event.cardId, event.closedItemId],
                [ci, event.template || ci.item.template, event.cardId]);
        }).catch(HandleErrors());
    };
    const handleOpenItemEvent = (event: OpenItemEvent) => {
        ItemFactory.openItem(event).then(ci =>
            openCard(ci, event.template || ci.item.template, event.cardId))
            .catch(HandleErrors());
    };
    const handleCloseItemEvent = (event: CloseItemEvent) => {
        closeCard(event.cardId, event.itemId);
        if (event.message)
            tactin()?.eventBus.notify(new ShowMessageEvent(event.message));
    };
    const handleOpenConfigurationEvent = (event: OpenConfigurationEvent) => { };

    const refreshSession = () => {
        if (sessionTimer)
            window.clearTimeout(sessionTimer.current);
        if (tactin().configuration.sessionTimeout())
            sessionTimer.current = window.setTimeout(() => {
                tactin()?.eventBus.notify(new LogoutEvent());
            }, tactin().configuration.sessionTimeout() * 1000);
    }

    const urlParams = new URLSearchParams(location.search);
    const panelName = urlParams.get('panel') || 'MainPanel';
    const panelConfig = urlParams.get('config') || 'MainPanel';

    const rootComponent = UIFactory.getViewerPanel(
        panelName,
        {},
        tactin().standardHandler(panelConfig) || {});

    return (<>
        {rootComponent.main}
        <CardManager cards={cards} />
        <FileDownloader />
        <PopupCustomUI />
        <TactinMessage />
        <WaitNotifier />
        <TokenOutdatedHandler />
    </>);
}

const urlParams = new URLSearchParams(location.search);
const admin = urlParams.get('admin') || '';
ReactDOM.render(<Tactin admin={admin === '1'} />, document.getElementById('root'));
