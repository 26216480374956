import React, { useState } from 'react';
import { ShowMessageEvent } from '../../model/events/TactinEvents';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { tactin } from '../../utils/TactinGlobals';
import { ImageBox } from '../basic/ImageBox';

const CacheReloadButtons: CacheReloadButton[] = [
    { caption: 'Reload validators', action: () => api().Admin.reloadValidators(), description: ['Reload item validators and mutators triggered when items are saved on the server.'] },
    { caption: 'Reload plugins', action: () => api().Admin.reloadPlugins(), description: ['Reload plugins installed on the server.'] },
    { caption: 'Reload permissions', action: () => api().Admin.reloadPermissions(), description: ['Reload permission groups and the criterias of when an item belongs to one.'] },
    { caption: 'Reload ShowAs', action: () => api().Admin.reloadShowAs(), description: ['Reload cached ShowAs scripts.', 'It is not neccessary to call this action, because if the ShowAs formula is not preset in the cache it will be generated and added to it.'] },
    { caption: 'Reload categories', action: () => api().Admin.reloadCategories(), description: ['Reload categories cached on the server.', 'This is required when either a category, property or item category property was changed in the database.'] },
    { caption: 'Split permission groups', action: () => api().Admin.splitPermissionGroups(), description: ['Duplicates the existing data permission groups that have menu options assigned to them as new iterface permission groups. It moves the menu options to the new permission group.', 'This is command reloads also the permission group cache.'] }
];

type CacheReloadButton = {
    caption: string;
    action: () => Promise<boolean>;
    description: string[];
}

export default function CacheReloadPanel() {
    const [selected, setSelect] = useState<CacheReloadButton | null>(null);

    function reload(action: () => Promise<boolean>) {
        action()
            .then((r: boolean) => {
                if (r)
                    tactin()?.eventBus.notify(new ShowMessageEvent('Successfully updated'))
                else
                    tactin()?.eventBus.notify(new ShowMessageEvent('Reload failed'))
            }).catch(HandleErrors());
    }

    return <div className='cachePanel'>
        <ImageBox iconName='databaseconfig' className='cachePanelHeader' label='On this panel you can reload server side caches. This is usefull when you made some manual changes in the database regarding data that is cached.' />
        <div className='panelContent'>
            <div className='buttonsBlock'>
                <div className='buttons'>
                    {CacheReloadButtons.map((i, ind) =>
                        <ImageBox key={ind} iconName='box41_32x32' className='functionsButton' label={i.caption} onClick={() => reload(i.action)}
                            onMouseMove={() => setSelect(i)} onMouseOut={() => setSelect(null)} />
                    )}
                </div>
            </div>
            <div className='adminDescriptionPanel'>
                {selected && <><h1>{selected.caption}</h1><hr />
                    {selected.description.map((i, ind) => <p key={ind}>{i}</p>)}</>}
            </div>
        </div>
    </div>
}
